var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"userDetail flex"},[_c('div',{staticClass:"fixed top-0 min-w-full bg-gray-600 md:pt-6 pb-48 pt-12"}),_c('div',{staticClass:"flex flex-wrap justify-between px-8 md:px-14 w-full",class:{
      'md:flex-row-reverse': _vm.$route.params.id != 'new'
    }},[(_vm.$route.params.id != 'new')?_c('div',{staticClass:"w-full lg:w-2/5 lg:pl-8"},[_c('SlideUp',[_c('div',{staticClass:"mr-4 bg-blueGray-200 p-5 relative hover:bg-blueGray-300 flex justify-center items-center w-full rounded-md"},[_c('div',{staticClass:"relative flex justify-center items-center content-center"},[_c('div',{staticClass:"relative flex justify-center items-center",class:{
                'h-32 w-32': _vm.url == ''
              }},[(_vm.url != '')?_c('img',{attrs:{"src":_vm.url}}):_vm._e(),(_vm.url == '')?_c('i',{staticClass:"fas fa-image text-3xl"}):_vm._e()])])])]),(_vm.$route.params.id != 'new')?_c('SlideUp',[_c('div',{staticClass:"mr-4 relative flex flex-col w-full mt-6 min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg p-8"},[_c('h5',{staticClass:"text-blueGray-400 uppercase font-bold text-xs mb-2"},[_vm._v(" "+_vm._s(_vm.$t("hubs"))+" ")]),_c('div',{staticClass:"flex flex-wrap my-2"},_vm._l((_vm.user.hubs),function(hub){return _c('span',{key:("user-hubs" + (hub.id)),staticClass:"bg-coolGray-100 mb-1 text-coolGray-400 border border-coolGray-200 rounded mr-1 text-xs px-2 py-1 flex items-center"},[_c('i',{staticClass:"fas fa-map-marker-alt mr-1"}),_vm._v(" "+_vm._s(hub.name)+" ")])}),0),_c('MSelect',{attrs:{"preSelected":_vm.user.hubs,"placeholder":_vm.$t('select hub(s)'),"options":_vm.$store.state.hubs.hubs},on:{"applySelection":_vm.applySelection,"cancelSelection":_vm.cancelSelection}})],1)]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"w-full lg:w-3/5"},[_c('SlideUp',{staticClass:"flex flex-wrap"},[_c('Form',{attrs:{"form":_vm.formData,"formName":_vm.formName,"options":_vm.options},scopedSlots:_vm._u([{key:"header",fn:function(){return [(_vm.$route.params.id != 'new')?_c('button',{staticClass:"text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150",class:{
                'bg-emerald-500': _vm.user.status == 'inactive',
                'bg-red-500': _vm.user.status == 'active'
              },attrs:{"type":"button"},on:{"click":function($event){return _vm.$store.dispatch('user/toggleUserStatus', _vm.$route.params.id)}}},[_vm._v(" "+_vm._s(_vm.activationText)+" ")]):_vm._e()]},proxy:true}])})],1),_c('SlideUp',[(_vm.user && _vm.user.checkouts && _vm.$route.params.id != 'new')?_c('ListTable',{attrs:{"fullwidth":true,"tableHeight":250,"tableName":((_vm.$t('checkout assets')) + ": " + (_vm.user.name)),"headers":_vm.headers,"tableData":_vm.user.checkouts,"searchable":false}}):_vm._e()],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }