// refactor and merge in future

export const getFieldValue = (f, obj) => {
  if (f?.isCustomData) {
    return obj["custom_data"][f.key];
  } else if ("parentKey" in f) {
    return obj[f.parentKey]?.[f.key];
  } else {
    return obj[f.key];
  }
};

export const bindData = (formData, obj) => {
  formData.forEach(fd => {
    fd.fields.forEach(f => {
      if (f?.isCustomData) {
        f.value = obj["custom_data"][f.key];
      } else if ("parentKey" in f) {
        f.value = obj[f.parentKey]?.[f.key];
      } else if (!(f.key in obj)) {
        // ignore changing value here so wont overwrite certain values like hiddens
      } else {
        f.value = obj[f.key];
      }
    });
  });
};
