<template>
  <div class="userDetail flex">
    <div class="fixed top-0 min-w-full bg-gray-600 md:pt-6 pb-48 pt-12" />
    <div
      class="flex flex-wrap justify-between px-8 md:px-14 w-full"
      :class="{
        'md:flex-row-reverse': $route.params.id != 'new'
      }"
    >
      <div class="w-full lg:w-2/5 lg:pl-8" v-if="$route.params.id != 'new'">
        <SlideUp>
          <div
            class="mr-4 bg-blueGray-200 p-5 relative hover:bg-blueGray-300 flex justify-center items-center w-full rounded-md"
          >
            <div
              class="relative flex justify-center items-center content-center"
            >
              <div
                class="relative flex justify-center items-center"
                :class="{
                  'h-32 w-32': url == ''
                }"
              >
                <img v-if="url != ''" :src="url" />
                <i v-if="url == ''" class="fas fa-image text-3xl" />
              </div>
            </div>
          </div>
        </SlideUp>
        <SlideUp v-if="$route.params.id != 'new'">
          <div
            class="mr-4 relative flex flex-col w-full mt-6 min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg p-8"
          >
            <h5 class="text-blueGray-400 uppercase font-bold text-xs mb-2">
              {{ $t("hubs") }}
            </h5>
            <div class="flex flex-wrap my-2">
              <span
                class="bg-coolGray-100 mb-1 text-coolGray-400 border border-coolGray-200 rounded mr-1 text-xs px-2 py-1 flex items-center"
                v-for="hub in user.hubs"
                :key="`user-hubs${hub.id}`"
              >
                <i class="fas fa-map-marker-alt mr-1" /> {{ hub.name }}
              </span>
            </div>

            <MSelect
              @applySelection="applySelection"
              @cancelSelection="cancelSelection"
              :preSelected="user.hubs"
              :placeholder="$t('select hub(s)')"
              :options="$store.state.hubs.hubs"
            />
          </div>
        </SlideUp>
      </div>
      <div class="w-full lg:w-3/5">
        <SlideUp class="flex flex-wrap">
          <Form :form="formData" :formName="formName" :options="options">
            <template v-slot:header>
              <button
                v-if="$route.params.id != 'new'"
                class="text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                :class="{
                  'bg-emerald-500': user.status == 'inactive',
                  'bg-red-500': user.status == 'active'
                }"
                type="button"
                @click="
                  $store.dispatch('user/toggleUserStatus', $route.params.id)
                "
              >
                {{ activationText }}
              </button>
            </template>
          </Form>
        </SlideUp>
        <SlideUp>
          <ListTable
            v-if="user && user.checkouts && $route.params.id != 'new'"
            :fullwidth="true"
            :tableHeight="250"
            :tableName="`${$t('checkout assets')}: ${user.name}`"
            :headers="headers"
            :tableData="user.checkouts"
            :searchable="false"
          />
        </SlideUp>
      </div>
    </div>
  </div>
</template>

<script>
import SlideUp from "@/components/transitions/SlideUp.vue";
import Form from "@/components/Form.vue";
// import RiderRatingChart from "@/components/RiderRatingChart.vue";
import { mapState } from "vuex";
import ListTable from "@/components/ListTable.vue";
import formatting from "../mixins/formatting";
import MSelect from "../components/MSelect.vue";
import { bindData } from "../helpers/form";
import userMixins from "@/mixins/userMixins";

export default {
  name: "UserDetail",
  mixins: [formatting, userMixins],
  components: {
    SlideUp,
    ListTable,
    // RiderRatingChart,
    MSelect,
    Form
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("companies", ["companies"]),
    ...mapState("suppliers", ["suppliers"]),
    ...mapState("hubs", ["hubs"]),
    ...mapState("groups", ["groups"]),
    options() {
      const { companies, suppliers, hubs, groups } = this;
      return { companies, suppliers, hubs, groups };
    },
    superuser() {
      return this.$store.state?.accounts?.user?.is_superuser;
    },
    formName: () => "User detail",
    url() {
      return this.user.profile_image;
    },
    activationText() {
      return this.user.status == "active"
        ? this.$t("deactivate account")
        : this.$t("activate account");
    }
  },
  data() {
    const isUpdate = this.$route.params.id != "new";
    const options =
      this.$store.state.accounts.user?.account_type == "manager"
        ? [
            { id: "manager", name: "manager" },
            { id: "rider", name: "rider" }
          ]
        : [
            { id: "admin", name: "admin" },
            { id: "manager", name: "manager" },
            { id: "rider", name: "rider" }
          ];
    let fields = [
      { key: "id", name: "id", type: "hidden", value: "" },
      {
        key: "email",
        name: "email",
        type: isUpdate ? "readonly" : "email",
        value: ""
      },
      { key: "name", name: "name", type: "text", value: "" },
      {
        name: "role",
        key: "account_type",
        value: "",
        type: "select",
        options,
        optionLabelKey: "name"
      },
      {
        name: "supplier",
        key: "supplier_id",
        value: "",
        type: "selectByOptionProp",
        options: "suppliers",
        optionLabelKey: "name"
      }
    ];

    if (this.$route.params.id == "new") {
      fields.push({
        name: "hubs",
        key: "hubs",
        value: [],
        type: "multiselectByOptionProp",
        options: "hubs",
        optionLabelKey: "name"
      });

      fields.push({
        name: "groups",
        key: "groups",
        value: [],
        type: "multiselectByOptionProp",
        options: "groups",
        optionLabelKey: "name"
      });
    }

    if (this.$store.state.accounts.user?.is_superuser) {
      fields.push({
        name: "company",
        key: "company_id",
        value: "",
        type: "selectByOptionProp",
        options: "companies",
        optionLabelKey: "name"
      });
    }
    let formData = [
      {
        section: "User Information",
        dispatch: isUpdate ? "user/updateUser" : "user/createUser",
        fields
      }
    ];
    if (isUpdate) {
      formData.push({
        section: "password reset",
        dispatch: "user/adminPasswordReset",
        fields: [
          { key: "id", name: "id", type: "hidden", value: "" },
          {
            key: "action",
            name: "action",
            type: "hidden",
            value: "admin_reset_password"
          },
          {
            name: "reset user password",
            key: "password",
            showPassword: false,
            value: "",
            type: "password"
          }
        ]
      });
    }
    return {
      headers: [
        { name: "Grab", key: "grabbed_at", type: "dateTime" },
        { name: "Return", key: "returned_at", type: "dateTime" },
        { name: "Asset", key: "bike.name", type: "" }
      ],
      formData
    };
  },
  watch: {
    user() {
      if (
        this.accountType != "admin" &&
        !this.isSuperAdmin &&
        this.user.id == this.myId
      ) {
        this.$router.push({ name: "UserSettings" });
      }
      bindData(this.formData, this.user);
    }
  },
  methods: {
    applySelection(hubs) {
      this.$store.dispatch("user/updateUser", {
        id: this.user.id,
        hubs
      });
    },
    cancelSelection() {
      this.$store.dispatch("user/getUser", this.$route.params.id);
    }
  },
  created() {
    if (this.$route.params.id != "new") {
      this.$store.dispatch("user/getUser", this.$route.params.id);
    } else {
      this.$store.commit("user/USER_RESET");
    }
    this.$store.dispatch("hubs/getAllHubs");
    this.$store.dispatch("suppliers/getAllSuppliers");
    this.$store.dispatch("groups/getGroups");

    if (this.$store.state.accounts.user?.is_superuser) {
      this.$store.dispatch("companies/getAllCompanies");
    }
  }
};
</script>
